import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const ScienceBook1 = () => {
  // animation package
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div className="row ScB1">
      <h2 className="text-center mb-4"> الرياضيات المتخصصة (1)</h2>

      {/* <!--card1--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=الاحتمالات&type=video/mp4">
            <img
              src="./images/subject2.jpeg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب الاحتمالات</p> <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=الاحتمالات&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
      {/* <!--card2--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=الاحصاء&type=video/mp4">
            <img
              src="./images/subject2.jpeg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب الاحصاء</p> <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=الاحصاء&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
      {/* <!--card3--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=الكسور&type=video/mp4">
            <img
              src="./images/subject2.jpeg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب الكسور</p>
              <span className="mb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=الكسور&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
      {/* <!--card4--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=المصفوفات&type=video/mp4">
            <img
              src="./images/subject2.jpeg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب المصفوفات</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=المصفوفات&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
      {/* <!--card5--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=مبدأ العد&type=video/mp4">
            <img
              src="./images/subject2.jpeg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب مبدأ العد</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=مبدأ العد&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
    </div>
  );
};
export default ScienceBook1;
