import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/users/authContext";
import { ErrContext } from "../../context/users/errContext";
import { Link } from "react-router-dom";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";

const SignUp = () => {
  const { auth, SignINUser, SignOutUser } = useContext(AuthContext);
  const { err, GetErrors, ClearErrors } = useContext(ErrContext);

  //state to manage form
  let [phoneNum, setPhoneNum] = useState("");
  let [username, setUsername] = useState("");
  let [confirmPhon, setConfirmPhon] = useState("");
  const [isloading, setIsloading] = useState(false);

  //animation package
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const handleSubmit = (e) => {
    setIsloading(true);
    e.preventDefault();
    //call to db
    let config = {
      headers: {
        "content-type": "application/json",
      },
    };
    let newUser = { phoneNum, username, confirmPhon };
    const body = JSON.stringify(newUser);
    //axios to db
    axios
      .post("/users/register", body, config)
      .then((res) => {
        setIsloading(false);
        SignINUser(res.data);
        ClearErrors();
      })
      .catch((err) => {
        setIsloading(false);
        GetErrors(err.response.data, err.response.status, "register_err");
        SignOutUser();
      });
  };
  return (
    <div className="Signup">
      <div className="section">
        <div data-aos="fade-up" className="box mt-5">
          <form action="" onSubmit={handleSubmit}>
            {auth.isAuthenticated ? (
              <div className="alert alert-success">
                <span className="text-center"> تم تسجيل بنجاح </span> <br />
                <Link className="btn btn8" to="/login">
                  {" "}
                  سجل الدخول
                </Link>
              </div>
            ) : null}
            <h1 className="display-5 text-center"> تسجيل جديد </h1>
            {isloading ? (
              <div className="row">
                <div className="col-12">
                  <div className="spinner-cont">
                    <div className="spinner-loader"></div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="form-group">
              <label className="label">اسم المستخدم</label>
              <input
                type="text"
                className="form-control"
                placeholder="اسم المستخدم"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>{" "}
            <br />
            <div className="form-group">
              <label className="label">رقم الهاتف</label>
              <input
                type="number"
                className="form-control"
                placeholder="ادخل رقم الهاتف"
                value={phoneNum}
                onChange={(e) => setPhoneNum(e.target.value)}
              />
            </div>{" "}
            <br />
            <div className="form-group">
              <label className="label">تأكيد رقم الهاتف</label>
              <input
                type="number"
                className="form-control"
                placeholder="تأكيد رقم الهاتف"
                value={confirmPhon}
                onChange={(e) => setConfirmPhon(e.target.value)}
              />
              <br />
              {/* display error message */}
              {err.id === "register_err" ? (
                <div className="errMsg">{err.msg.msg}</div>
              ) : null}
            </div>{" "}
            <br />
            <div className="text-center">
              <button className="btn btn3">ارسل</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
