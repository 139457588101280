import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/users/authContext";
import { Link } from "react-router-dom";
import ScienceBook1 from "./scienceBook1";
import ScienceBook2 from "./scienceBook2";
import ArtBook from "./artBook";
import RevisionLessons from "../revision Lesson/revisionLessons";
import MathFirst from "../first-second/math-first";
import MathSecond from "../first-second/math-second";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../zoom.css";

const Subjects = () => {
  const { auth } = useContext(AuthContext);
  const [value, setValue] = useState("all");
  let [ordered, setOrdered] = useState(false);
  const [isloading, setIsloading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  //anime package scroll reveal
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  //order all books in the website
  const OrderAll = (userId) => {
    setIsloading(true);
    //post directly to data base
    let data = { userId };
    axios
      .post("/v1/orders/special-order", data)
      .then((res) => {
        setOrdered((prev) => !prev);
        setIsloading(false);
      })
      .catch((err) => {
        setErrMsg(err.response.data);
        setIsloading(false);
        console.log(err);
      });
  };

  //order a book
  const OrderBook = (kind, booknum, userId, price) => {
    setIsloading(true);
    let data = { kind, booknum, userId, price };
    console.log(data);
    //post to database
    axios
      .post("/v1/orders/order-book", data)
      .then((res) => {
        setOrdered((prev) => !prev);
        setIsloading(false);
        console.log(res);
      })
      .catch((err) => {
        setErrMsg(err.response.data);
        setIsloading(false);
        console.log(err);
      });
  };
  //order all science revision lessons
  const OrderSpecialBook = (userId, price) => {
    setIsloading(true);
    let data = { userId, price };
    console.log(data);
    //post to database
    axios
      .post("/v1/orders/special-book", data)
      .then((res) => {
        setOrdered((prev) => !prev);
        setIsloading(false);
        console.log(res);
      })
      .catch((err) => {
        setErrMsg(err.response.data);
        setIsloading(false);
        console.log(err);
      });
  };

  const ZoomAccess = (userId) => {
    setIsloading(true);

    let data = { userId };

    axios
      .post("/v1/joinZoom/", data)
      .then((res) => {
        setIsloading(false);
        setOrdered((prev) => !prev);
        console.log(res);
      })
      .catch((err) => {
        setErrMsg(err.response.data);
        setIsloading(false);
        console.log(err);
      });
  };

  return (
    <div className="homepage">
      {/* <!--header--> */}
      <div className="container-fluid cont6">
        <div className="text-center">
          <h1 className="display-3 h1">المواد</h1>
          <p>
            <Link className="sub-H" to="/">
              الرئيسية
            </Link>{" "}
            /{" "}
            <Link className="sub-H" to="/subjects">
              المواد
            </Link>
          </p>
        </div>
      </div>
      {/* <!--end of header--> */}
      <br />
      <br />

      {/* request to join zoom  */}
      {/* {auth.user && (
        <>
          {auth.user.username !== "muaz@admin" &&
            auth.user.role !== "apptojoinzoom" && (
              <div className="JoinZoom">
                <div className="JZ-content">
                  <h4>طلب الانضمام لمجموعة الحصص المباشرة (zoom)</h4>
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#zoomModal"
                  >
                    انضم الان
                  </button>
                  <div
                    className="modal fade"
                    id="zoomModal"
                    tabIndex="-1"
                    aria-labelledby="zoomModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="zoomModal">
                            طلب الانضمام للحصص المباشرة
                          </h5>
                        </div>
                        <div className="modal-body">
                          <h5>
                            بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ معاذ
                            على الرقم 0912212622 وتحويل المبلغ على رقم الحساب
                            2916188
                          </h5>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                          >
                            الغاء
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-bs-dismiss="modal"
                            onClick={() => ZoomAccess(auth.user._id)}
                          >
                            تأكيد الطلب
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            )}
        </>
      )} */}

      {/*ends here */}
      {isloading ? (
        <div className="row">
          <div className="col-12">
            <div className="spinner-cont">
              <div className="spinner-loader"> </div>
            </div>
          </div>
        </div>
      ) : null}

      {/* <!--subject container--> */}
      <div className="cont-7">
        <br />
        {errMsg ? (
          <h3 className="container alert alert-danger">{errMsg} </h3>
        ) : null}
        {/* {auth.isAuthenticated ? (
          auth.user.role === "basic" ? (
            <div className="container text-center mt-5">
              <h3 className="text-center order-all">
                الان يمكنك طلب كتابي رياضيات الصف الثالث بقيمة 100,000 جنيه
              </h3>
              <button
                type="button"
                className="btn btn-primary btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                تأكيد الطلب
              </button>
              <div
                className="modal fade"
                id="exampleModal"
                tabIndex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModal">
                        تأكيد الطلب{" "}
                      </h5>
                    </div>
                    <div className="modal-body">
                      <p>
                        بعد اتمام الطلب الرجاء التواصل مع الاستاذ بالرقم
                        الموجودة على الصفحة الرئيسية او 0912212622
                      </p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        الغاء
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        data-bs-dismiss="modal"
                        onClick={() => OrderAll(auth.user._id)}
                        disabled={isloading}
                      >
                        تأكيد
                      </button>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          ) : null
        ) : null} */}

        <div className="row w-100">
          {ordered ? (
            <h4
              className={` alert alert-success back-message ${
                ordered ? "back-message-anim" : null
              }`}>
              تم الطلب بنجاح الرجاء التواصل عن طريق االايميل او الهاتف الموجودة
              على الصفحة الرئيسية{" "}
            </h4>
          ) : null}
          {/* options div */}
          <div className="col-lg-2 col-md-3 col-sm-12 ">
            <form data-aos="fade-right" className="sub-form">
              <h4 className="display-7">تصنيف</h4>
              <input
                type="radio"
                id="html"
                name="videos"
                value="all"
                onChange={(e) => setValue(e.target.value)}
              />
              <label htmlFor="html">الكل</label>
              <br />
              <input
                type="radio"
                id="science1"
                name="videos"
                value="science1"
                onChange={(e) => setValue(e.target.value)}
              />
              <label htmlFor="science1">المتخصصة 1</label>
              <br />
              <input
                type="radio"
                id="science2"
                name="videos"
                value="science2"
                onChange={(e) => setValue(e.target.value)}
              />
              <label htmlFor="science2">المتخصصة 2</label>
              <br />
              <input
                type="radio"
                id="art"
                name="videos"
                value="art"
                onChange={(e) => setValue(e.target.value)}
              />
              <label htmlFor="art">الاساسية </label>
              <br />
              <input
                type="radio"
                id="revision"
                name="videos"
                value="revision"
                onChange={(e) => setValue(e.target.value)}
              />
              <label htmlFor="revision">حصص المراجعة </label>
              <br />
              <input
                type="radio"
                id="math-1th"
                name="videos"
                value="math-1th"
                onChange={(e) => setValue(e.target.value)}
              />
              <label htmlFor="math-1th"> رياضيات الصف الاول </label>
              <br />
              <input
                type="radio"
                id="math-2th"
                name="videos"
                value="math-2th"
                onChange={(e) => setValue(e.target.value)}
              />
              <label htmlFor="math-2th">رياضيات الصف الثاني </label>
            </form>
          </div>
          {/* options body */}
          <div className="col-lg-9 col-md-9 col-sm-12">
            {value === "all" ? (
              <>
                <h2 className="display-5 text-center sub-H1">
                  {" "}
                  رياضيات الصف الثالث
                </h2>
                {auth.user ? (
                  auth.user.username !== "muaz@admin" ? (
                    <>
                      <div className="text-center mb-3 books">
                        {" "}
                        <h3>اطلب الكتاب الاول بقيمة 15,000 جنيه</h3>{" "}
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal">
                          اطلب الكتاب
                        </button>
                        <div
                          className="modal fade"
                          id="exampleModal"
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModal">
                                  طلب كتاب المتخصصة 1{" "}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <h5>
                                  بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ
                                  معاذ على الرقم الموضح في الصفحة الرئيسية او
                                  0912212622
                                </h5>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal">
                                  الغاء
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    OrderBook(
                                      "علمي",
                                      "الكتاب الأول",
                                      auth.user._id,
                                      15000
                                    )
                                  }>
                                  تأكيد الطلب
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </>
                  ) : null
                ) : null}
                <ScienceBook1 />
                <br /> <br />
                {auth.user ? (
                  auth.user.username !== "muaz@admin" ? (
                    <>
                      <div className="text-center mb-3 books">
                        {" "}
                        <h3>اطلب الكتاب الثاني بقيمة 15,000 جنيه</h3>{" "}
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#modalB1">
                          اطلب الكتاب
                        </button>
                        <div
                          className="modal fade"
                          id="modalB1"
                          tabIndex="-1"
                          aria-labelledby="modalB1Label"
                          aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="modalB1">
                                  طلب كتاب المتخصصة 2{" "}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <h5>
                                  بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ
                                  معاذ على الرقم الموضح في الصفحة الرئيسية او
                                  0912212622
                                </h5>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal">
                                  الغاء
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    OrderBook(
                                      "علمي",
                                      "الكتاب الثاني",
                                      auth.user._id,
                                      15000
                                    )
                                  }>
                                  تأكيد الطلب
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </>
                  ) : null
                ) : null}
                <ScienceBook2 />
                {/* <!--subjects container ends here--> */}
                {/* <!--subject container2--> */}
                <br />
                {auth.user ? (
                  auth.user.username !== "muaz@admin" ? (
                    <>
                      <div className="text-center mb-3 books">
                        {" "}
                        <h3>اطلب كتاب الأدبي بقيمة 20,000 جنيه</h3>{" "}
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#modalB2">
                          اطلب الكتاب
                        </button>
                        <div
                          className="modal fade"
                          id="modalB2"
                          tabIndex="-1"
                          aria-labelledby="modalB2Label"
                          aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="modalB2">
                                  طلب كتاب الاساسية{" "}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <h5>
                                  بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ
                                  معاذ على الرقم الموضح في الصفحة الرئيسية او
                                  0912212622
                                </h5>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal">
                                  الغاء
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    OrderBook(
                                      "أدبي",
                                      "كتاب الأدبي",
                                      auth.user._id,
                                      20000
                                    )
                                  }>
                                  تأكيد الطلب
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </>
                  ) : null
                ) : null}
                <ArtBook />
                <br /> <br />
                {/* <!--subjects container ends here--> */}
                {/* revisions videos starts here*/}
                {auth.user ? (
                  auth.user.username !== "muaz@admin" ? (
                    <>
                      <div className="text-center mb-3 books">
                        <h3> طلبات حصص المراجعة </h3>{" "}
                        <h5>
                          {" "}
                          الرياضيات المتخصصة 10,000 جنيه // الرياضيات الاساسية
                          10,000 جنيه
                        </h5>
                        <button
                          type="button"
                          className="btn btn-primary modal-btn1"
                          data-bs-toggle="modal"
                          data-bs-target="#modalB4">
                          اطلب كتابي المتخصصة (1) + (2)
                        </button>
                        <div
                          className="modal fade"
                          id="modalB4"
                          tabIndex="-1"
                          aria-labelledby="modalB4Label"
                          aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="modalB4">
                                  طلب كتابي المتخصصة{" "}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <h5>
                                  بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ
                                  معاذ على الرقم الموضح في الصفحة الرئيسية او
                                  0912212622
                                </h5>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal">
                                  الغاء
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    OrderSpecialBook(auth.user._id, 10000)
                                  }>
                                  تأكيد الطلب
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        {/* modal for basic revision books */}{" "}
                        <button
                          type="button"
                          className="btn btn-primary btn11"
                          data-bs-toggle="modal"
                          data-bs-target="#modalB3">
                          اطلب كتاب الاساسية
                        </button>
                        <div
                          className="modal fade"
                          id="modalB3"
                          tabIndex="-1"
                          aria-labelledby="modalB3Label"
                          aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="modalB3">
                                  طلب كتاب الاساسية{" "}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <h5>
                                  بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ
                                  معاذ على الرقم الموضح في الصفحة الرئيسية او
                                  0912212622
                                </h5>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal">
                                  الغاء
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    OrderBook(
                                      "حصص مراجعة",
                                      "كتاب الأدبي",
                                      auth.user._id,
                                      10000
                                    )
                                  }>
                                  تأكيد الطلب
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </>
                  ) : null
                ) : null}
                <RevisionLessons />
                {/* first math  */}
                <h2 className="display-5 text-center sub-H3">
                  رياضيات الصف الاول ثانوي
                </h2>
                <MathFirst />
                {/* math second */}
                <h2 className="display-5 text-center sub-H3">
                  رياضيات الصف الثاني ثانوي
                </h2>
                <MathSecond />
              </>
            ) : value === "science1" ? (
              <>
                <h2 className="display-5 text-center sub-H1">
                  {" "}
                  رياضيات الصف الثالث
                </h2>
                {auth.user ? (
                  auth.user.username !== "muaz@admin" ? (
                    <>
                      <div className="text-center mb-3 books">
                        {" "}
                        <h3>اطلب الكتاب الاول بقيمة 15,000 جنيه</h3>{" "}
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal">
                          اطلب الكتاب
                        </button>
                        <div
                          className="modal fade"
                          id="exampleModal"
                          tabIndex="-1"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModal">
                                  طلب كتاب المتخصصة 1{" "}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <h5>
                                  بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ
                                  معاذ على الرقم الموضح في الصفحة الرئيسية او
                                  0912212622
                                </h5>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal">
                                  الغاء
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    OrderBook(
                                      "علمي",
                                      "الكتاب الأول",
                                      auth.user._id,
                                      15000
                                    )
                                  }>
                                  تأكيد الطلب
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </>
                  ) : null
                ) : null}
                <ScienceBook1 />
              </>
            ) : value === "science2" ? (
              <>
                <h2 className="display-5 text-center sub-H1">
                  {" "}
                  رياضيات الصف الثالث
                </h2>
                {auth.user ? (
                  auth.user.username !== "muaz@admin" ? (
                    <>
                      <div className="text-center mb-3 books">
                        {" "}
                        <h3>اطلب الكتاب الثاني بقيمة 15,000 جنيه</h3>{" "}
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#modalB1">
                          اطلب الكتاب
                        </button>
                        <div
                          className="modal fade"
                          id="modalB1"
                          tabIndex="-1"
                          aria-labelledby="modalB1Label"
                          aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="modalB1">
                                  طلب كتاب المتخصصة 2{" "}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <h5>
                                  بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ
                                  معاذ على الرقم الموضح في الصفحة الرئيسية او
                                  0912212622
                                </h5>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal">
                                  الغاء
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    OrderBook(
                                      "علمي",
                                      "الكتاب الثاني",
                                      auth.user._id,
                                      15000
                                    )
                                  }>
                                  تأكيد الطلب
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </>
                  ) : null
                ) : null}
                <ScienceBook2 />
              </>
            ) : value === "art" ? (
              <div className="cont5 cont-5">
                <h2 className="display-5 text-center sub-H2">
                  رياضيات الصف الثالث
                </h2>
                {auth.user ? (
                  auth.user.username !== "muaz@admin" ? (
                    <>
                      <div className="text-center mb-3 books">
                        {" "}
                        <h3>اطلب كتاب الأدبي بقيمة 20,000 جنيه</h3>{" "}
                        <button
                          type="button"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#modalB2">
                          اطلب الكتاب
                        </button>
                        <div
                          className="modal fade"
                          id="modalB2"
                          tabIndex="-1"
                          aria-labelledby="modalB2Label"
                          aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="modalB2">
                                  طلب كتاب الاساسية{" "}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <h5>
                                  بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ
                                  معاذ على الرقم الموضح في الصفحة الرئيسية او
                                  0912212622
                                </h5>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal">
                                  الغاء
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    OrderBook(
                                      "أدبي",
                                      "كتاب الأدبي",
                                      auth.user._id,
                                      20000
                                    )
                                  }>
                                  تأكيد الطلب
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </>
                  ) : null
                ) : null}
                <ArtBook />
              </div>
            ) : value === "revision" ? (
              <>
                <h2 className="display-5 text-center sub-H3">
                  رياضيات الصف الثالث
                </h2>
                {auth.user ? (
                  auth.user.username !== "muaz@admin" ? (
                    <>
                      <div className="text-center mb-3 books">
                        <h3> جنيه 20,000 طلبات حصص المراجعة</h3>{" "}
                        <button
                          type="button"
                          className="btn btn-primary modal-btn1"
                          data-bs-toggle="modal"
                          data-bs-target="#modalB4">
                          اطلب كتابي المتخصصة (1) + (2)
                        </button>
                        <div
                          className="modal fade"
                          id="modalB4"
                          tabIndex="-1"
                          aria-labelledby="modalB4Label"
                          aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="modalB4">
                                  طلب كتابي المتخصصة{" "}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <h5>
                                  بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ
                                  معاذ على الرقم الموضح في الصفحة الرئيسية او
                                  0912212622
                                </h5>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal">
                                  الغاء
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    OrderSpecialBook(auth.user._id, 10000)
                                  }>
                                  تأكيد الطلب
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        {/* modal for basic revision books */}{" "}
                        <button
                          type="button"
                          className="btn btn-primary btn11"
                          data-bs-toggle="modal"
                          data-bs-target="#modalB3">
                          اطلب كتاب الاساسية
                        </button>
                        <div
                          className="modal fade"
                          id="modalB3"
                          tabIndex="-1"
                          aria-labelledby="modalB3Label"
                          aria-hidden="true">
                          <div className="modal-dialog">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="modalB3">
                                  طلب كتاب الاساسية{" "}
                                </h5>
                              </div>
                              <div className="modal-body">
                                <h5>
                                  بعد تأكيد طلب الكتاب الرجاء التواصل مع استاذ
                                  معاذ على الرقم الموضح في الصفحة الرئيسية او
                                  0912212622
                                </h5>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal">
                                  الغاء
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-primary "
                                  data-bs-dismiss="modal"
                                  onClick={() =>
                                    OrderBook(
                                      "حصص مراجعة",
                                      "كتاب الأدبي",
                                      auth.user._id,
                                      10000
                                    )
                                  }>
                                  تأكيد الطلب
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </>
                  ) : null
                ) : null}
                <RevisionLessons />
              </>
            ) : value === "math-1th" ? (
              <>
                <h2 className="display-5 text-center sub-H3">
                  رياضيات الصف الاول ثانوي
                </h2>
                <MathFirst />
              </>
            ) : value === "math-2th" ? (
              <>
                <h2 className="display-5 text-center sub-H3">
                  رياضيات الصف الثاني ثانوي
                </h2>
                <MathSecond />
              </>
            ) : null}
          </div>
        </div>
      </div>
      <footer>حقوق الطبع محفوظة لصالح استاذ &copy;معاذ</footer>
    </div>
  );
};

export default Subjects;
