import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/users/authContext";
import { VideoContext } from "../../context/users/VideoContext";
import Aos from "aos";
import "aos/dist/aos.css";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const { AddVideo, video } = useContext(VideoContext);
  const [order, setOrder] = useState([]);
  const [progress, setProgress] = useState();
  const [isArrived, setIsArrived] = useState(false);
  const [postSuc, setPostSuc] = useState(false);
  const [isloading, setIsloading] = useState(false);

  let [deleted, setDeleted] = useState(false);
  let [permGranted, setPermGranted] = useState(false);
  let [errMsg, setErrMsg] = useState("");

  const [zoomLink, setZoomLink] = useState("");
  const [passcode, setPasscode] = useState("");
  const [meetingNumber, setMeetingNumber] = useState("");

  //request states to add a new video
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [subject, setSubject] = useState("");
  const [kind, setKind] = useState("");
  const [booknum, setBooknum] = useState("");
  const [chapter, setChapter] = useState("");
  const [subName, setSubName] = useState("");
  const [price, setPrice] = useState("");
  const [details, setDetails] = useState("");

  //define classList when db success

  //function to get orders
  useEffect(() => {
    //initialize animation package
    Aos.init({ duration: 2000 });

    let token = auth.token;

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    //cal to databse to get orders
    axios.get("/v1/orders/get-request", config).then((res) => {
      setOrder(res.data);
      setIsArrived(true);
    });
  }, [deleted, permGranted]);

  //useeffect to check if there is a meeting id
  useEffect(() => {
    //call the end point
    if (auth.user) {
      let userId = auth.user._id;
      let data = { userId };
      axios
        .post("/v1/joinZoom/get-credentials", data)
        .then((res) => {
          console.log(res.data);
          setMeetingNumber(res.data.meetingNumber);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [deleted]);

  //function to give access
  const giveAccess = (userId, videoId, orderId) => {
    let adminId = auth.user._id;
    let data = { userId, videoId, orderId, adminId };
    console.log(data);
    axios
      .post("/v1/orders/give-access", data)
      .then((res) => {
        console.log(res);
        setPermGranted((prev) => !prev);
      })
      .catch((err) => {
        setErrMsg(err.response.data);
        console.log(err);
      });
  };
  //function to give access to all
  // const giveAccessAll = () => {
  //   let orderIds = [];
  //   if (order) {
  //     order.map((order1) => {
  //       orderIds.push(order1._id);
  //     });
  //   }
  //   //post to server
  //   let adminId = auth.user._id;
  //   let data = { orderIds, adminId };
  //   axios
  //     .post("/v1/orders/give-access-all", data)
  //     .then((res) => {
  //       console.log(res);
  //       setPermGranted((prev) => !prev);
  //     })
  //     .catch((err) => console.log(err));
  // };

  //function to delete request
  const deleteRequest = (orderId) => {
    let adminId = auth.user._id;
    let data = { orderId, adminId };
    //setup token in headers
    let token = auth.token;
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    //post to server
    axios
      .post("/v1/orders/delete-req", data, config)
      .then((res) => {
        console.log(res.data);
        setDeleted((prev) => !prev);
        setErrMsg("");
      })
      .catch((err) => console.log(err));
  };
  //function to delete all request
  // const deleteRequestAll = () => {
  //   let orderIds = [];
  //   if (order) {
  //     order.map((order1) => {
  //       orderIds.push(order1._id);
  //     });
  //   }
  //   //post to server
  //   let adminId = auth.user._id;
  //   let data = { orderIds, adminId };
  //   axios
  //     .post("/v1/orders/delete-req-all", data)
  //     .then((res) => {
  //       console.log(res);
  //       setDeleted((prev) => !prev);
  //     })
  //     .catch((err) => console.log(err));
  // };

  //handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    let id = auth.user._id;
    const data = new FormData();
    data.append("type", type);
    data.append("subject", subject);
    data.append("kind", kind);
    data.append("booknum", booknum);
    data.append("chapter", chapter);
    data.append("subName", subName);
    data.append("price", price);
    data.append("details", details);
    data.append("id", id);
    data.append("file", file);

    // let token = auth.token;

    // let config = {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // };
    // if (token) {
    //   config.headers["x-auth-token"] = token;
    // }
    //post data to server
    const ProgressBar = {
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .post("/v1/vedios/post-video", data, ProgressBar)
      .then((res) => {
        AddVideo(res.data);
        setPostSuc(true);
      })
      .catch((err) => console.log(err));
  };
  //handle submit2
  const handleSubmit2 = (adminId) => {
    setIsloading(true);

    //if form is empty join directly the previuos session
    let data = { adminId, zoomLink, passcode };

    if (zoomLink !== "" && passcode !== "") {
      axios
        .post("/v1/joinZoom/create", data)
        .then((res) => {
          setIsloading(false);
          navigate(`/getzoom/join/${adminId}`);
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });
    } else {
      navigate(`/getzoom/join/${adminId}`);
    }
  };

  //request to delete all data
  const deleteZmData = (adminId) => {
    setIsloading(true);

    let data = { adminId };

    axios
      .post("/v1/joinZoom/delete-credentials", data)
      .then((res) => {
        setIsloading(false);
        setDeleted(true);
        console.log(res.data);
      })
      .catch((err) => {
        setIsloading(false);
        console.log(err);
      });
  };
  return (
    <div className="admin">
      <div className="container-fluid header">
        <div data-aos="fade-down" className="text-center D-header">
          <h1 className="display-4 pt-3 ">مرحبا مجددا في لوحة التحكم</h1>
          <img src="./images/dashboard-icon.png" className="D-img" alt="logo" />
        </div>
      </div>
      {auth.isAuthenticated ? (
        auth.user.username === "muaz@admin" ? (
          <div className="container-fluid w-100">
            <br />
            {isloading ? (
              <div className="row">
                <div className="col-12">
                  <div className="spinner-cont">
                    <div className="spinner-loader"></div>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="JoinZoom">
              <div className="zoom-form">
                <h4>ادخل بيانات الحصة المباشرة على برنامجzoom</h4>
                <input
                  type="number"
                  placeholder="رقم الاجتماع"
                  value={zoomLink}
                  onChange={(e) => setZoomLink(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="ادخل passcode"
                  value={passcode}
                  onChange={(e) => setPasscode(e.target.value)}
                />
                <br />
                <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={() => handleSubmit2(auth.user._id)}>
                  بدء الاجتماع
                </button>
                {meetingNumber && (
                  <button
                    className="btn btn-danger del-zoom"
                    onClick={() => deleteZmData(auth.user._id)}>
                    احذف بيانات الاجتماع السابق
                  </button>
                )}
              </div>
            </div>
            <br />
            <div className="row D-body">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <h1 className="text-center">اضف فيديو جديد</h1>

                {video.isArrived ? (
                  <div
                    className={`alert alert-success back-message ${
                      postSuc ? "back-message-anim " : null
                    }`}>
                    تم اضافة المحتوى بنجاح
                  </div>
                ) : null}

                <form
                  data-aos="fade-left"
                  data-aos-duration="1000"
                  className="post"
                  onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="subject" className="label">
                      المحتوى
                    </label>
                    <select
                      className="form-select D-input"
                      aria-label="Default select example"
                      dir="ltr"
                      value={type}
                      onChange={(e) => setType(e.target.value)}>
                      <option defaultValue={null}>اختر</option>
                      <option value="video/mp4">فيديو</option>
                      <option value=".pdf">pdf</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="file" className="label">
                      الملف
                    </label>
                    <input
                      type="file"
                      placeholder="اختر الملف"
                      className="form-control D-input"
                      id="file"
                      accept={type}
                      onChange={(e) => setFile(e.target.files[0])}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="subject" className="label">
                      المادة
                    </label>
                    <select
                      className="form-select D-input"
                      aria-label="Default select example"
                      dir="ltr"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}>
                      <option defaultValue={null}>اختر</option>
                      <option value="رياضيات الصف الثالث">
                        رياضيات الصف الثالث
                      </option>
                      <option value="رياضيات الصف الثانوي">
                        رياضيات الصف الثانوي
                      </option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="kind" className="label">
                      النوع
                    </label>
                    <select
                      className="form-select D-input"
                      aria-label="Default select example"
                      dir="ltr"
                      value={kind}
                      onChange={(e) => setKind(e.target.value)}>
                      <option defaultValue={null}>اختر </option>
                      {subject === "رياضيات الصف الثالث" ? (
                        <>
                          {" "}
                          <option value="علمي">المتخصصة</option>
                          <option value="أدبي">الاساسية</option>
                          <option value="حصص مراجعة">حصص مراجعه</option>{" "}
                        </>
                      ) : subject === "رياضيات الصف الثانوي" ? (
                        <>
                          <option value="الصف الاول">الصف الاول</option>
                          <option value="الصف الثاني">الصف الثاني</option>
                        </>
                      ) : null}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="booknum" className="label">
                      الكتاب
                    </label>
                    <select
                      className="form-select D-input"
                      aria-label="Default select example"
                      dir="ltr"
                      value={booknum}
                      onChange={(e) => setBooknum(e.target.value)}>
                      <option defaultValue={null}>اختر </option>
                      {subject === "رياضيات الصف الثالث" ? (
                        <>
                          <option value="الكتاب الأول"> العلمي الأول</option>
                          <option value="الكتاب الثاني">العلمي الثاني</option>
                          <option value="كتاب الأدبي"> الأدبي</option>
                        </>
                      ) : subject === "رياضيات الصف الثانوي" ? (
                        <>
                          <option value="كتاب الصف الاول">
                            كتاب الصف الاول
                          </option>
                          <option value="كتاب الصف الثاني">
                            كتاب الصف الثاني
                          </option>
                        </>
                      ) : null}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="chapter" className="label">
                      اسم الباب
                    </label>
                    <select
                      className="form-select D-input"
                      aria-label="Default select example"
                      dir="ltr"
                      value={chapter}
                      onChange={(e) => setChapter(e.target.value)}>
                      <option defaultValue={null}>اختر </option>

                      {subject === "رياضيات الصف الثالث" ? (
                        <>
                          <optgroup label="الكتاب العلمي الاول">
                            <option value="الاحتمالات">الاحتمالات </option>
                            <option value="الاحصاء">الاحصاء </option>
                            <option value="الكسور">الكسور </option>
                            <option value="المصفوفات">المصفوفات </option>
                            <option value="مبدأ العد">مبدأ العد </option>
                          </optgroup>

                          <optgroup label="الكتاب العلمي الثاني">
                            <option value="الاعداد المركبة">
                              الاعداد المركبة{" "}
                            </option>
                            <option value="التفاضل">التفاضل </option>
                            <option value="التكامل">التكامل </option>
                            <option value="الدائرة">الدائرة </option>
                            <option value="الدوال">الدوال </option>
                            <option value="تطبيقات التفاضل">
                              تطبيقات التفاضل{" "}
                            </option>
                          </optgroup>

                          <optgroup label="الكتاب الادبي">
                            <option value=" الدوال الحقيقية والنهايات">
                              {" "}
                              الدوال الحقيقية والنهايات{" "}
                            </option>
                            <option value="التفاضل">التفاضل </option>
                            <option value="التكامل">التكامل </option>
                            <option value="الاحصاء">الاحصاء </option>
                            <option value="الاحتمالات">الاحتمالات </option>
                            <option value=" المصفوفات"> المصفوفات </option>
                          </optgroup>
                        </>
                      ) : subject === "رياضيات الصف الثانوي" ? (
                        <>
                          <optgroup label="رياضيات الصف الاول">
                            <option value="المنطق الرياضي">
                              المنطق الرياضي{" "}
                            </option>
                            <option value="الدوال المثلثية">
                              الدوال المثلثية{" "}
                            </option>
                            <option value="المجموعات">المجموعات </option>
                            <option value="العلاقات">العلاقات </option>
                            <option value="التغيير">التغيير </option>
                            <option value="الهندسة التحليلية">
                              الهندسة التحليلية{" "}
                            </option>
                            <option value="كثيرات الحدود">
                              كثيرات الحدود{" "}
                            </option>
                          </optgroup>

                          <optgroup label="رياضيات الصف الثاني">
                            <option value="الهندسة التحليلية">
                              الهندسة التحليلية{" "}
                            </option>
                            <option value="الدالة الاسية">
                              الدالة الاسية{" "}
                            </option>
                            <option value="الدالة اللوغريثمية">
                              الدالة اللوغريثمية{" "}
                            </option>
                            <option value="الجذور الصم">الجذور الصم </option>
                            <option value="حساب المثلثات">
                              حساب المثلثات{" "}
                            </option>
                            <option value="المتتاليات">المتتاليات</option>
                            <option value="المتباينات">المتباينات</option>
                            <option value="نظرية الباقي و العامل">
                              نظرية الباقي و العامل{" "}
                            </option>
                            <option value="مجموعة الاعداد المركبة">
                              مجموعة الاعداد المركبة{" "}
                            </option>
                          </optgroup>
                        </>
                      ) : null}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="subName" className="label">
                      اسم الدرس
                    </label>
                    <input
                      type="text"
                      placeholder=" ادخل اسم الدرس"
                      className="form-control D-input"
                      value={subName}
                      onChange={(e) => setSubName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="price" className="label">
                      الترتيب
                    </label>
                    <input
                      type="number"
                      placeholder="حدد ترتيب الدرس"
                      className="form-control D-input"
                      value={price}
                      onChange={(e) => setPrice(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="details" className="label">
                      التفاصيل
                    </label>
                    <textarea
                      type="number"
                      placeholder=" تفاصيل الدرس"
                      className="form-control D-input"
                      value={details}
                      onChange={(e) => setDetails(e.target.value)}>
                      {" "}
                    </textarea>
                  </div>
                  <div className="butn">
                    <button type="submit" className="btn btn8 D-btn">
                      ارسل
                    </button>
                  </div>
                </form>
                <br />
                {progress ? (
                  <div className="progress" style={{ height: 20 }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${progress}%` }}
                      aria-valuenow={`${progress}`}
                      aria-valuemin="0"
                      aria-valuemax="100">
                      {progress}
                    </div>
                  </div>
                ) : null}
              </div>

              {isArrived ? (
                <div className="col-lg-8 col-md-7 col-sm-12 order">
                  {permGranted ? (
                    <div
                      className={`alert alert-success back-message ${
                        permGranted ? "back-message-anim" : null
                      }`}>
                      تم اعطاء الاذن بنجاح الرجاء التواصل مع الطالب المعني
                    </div>
                  ) : null}
                  <h1 className="display-5 text-center ">طلبات الفيديوهات</h1>
                  <h4 className="text-center text-danger">
                    still under test do not use it yet !!!!!
                  </h4>
                  <div className="text-center all-btns">
                    {/*access all btn */}
                    <button
                      type="button"
                      className="btn btn-primary all-1"
                      data-bs-toggle="modal"
                      data-bs-target="#modal3">
                      اعطي الاذن للكل
                    </button>

                    <div
                      className="modal fade"
                      id="modal3"
                      tabIndex="-1"
                      aria-labelledby="modal3Label"
                      aria-hidden="true">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="modal3">
                              اعطاء الاذن{" "}
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>
                              هل انت متأكد برغبتك في اعطاء الاذن لجميع الطلاب{" "}
                            </p>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal">
                              الغاء
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary "
                              data-bs-dismiss="modal"
                              onClick={() => console.log("clicked")}>
                              اعطي الاذن
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*delete all btn */}
                    <button
                      type="button"
                      className="btn btn-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#modal4">
                      احذف الكل
                    </button>

                    <div
                      className="modal fade"
                      id="modal4"
                      tabIndex="-1"
                      aria-labelledby="modal4Label"
                      aria-hidden="true">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id="modal4">
                              حذف الطلبات{" "}
                            </h5>
                          </div>
                          <div className="modal-body">
                            <p>هل انت متأكد برغبتك في حذف جميع الطلبات </p>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal">
                              الغاء
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger "
                              data-bs-dismiss="modal"
                              onClick={() => console.log("clickedc now")}>
                              احذف الكل
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul className="list-group list-group-flush pb-5">
                    {order.map((order1) => {
                      return (
                        <>
                          <li className="list-group-item l1" key={order1._id}>
                            <p>
                              {order1.username}
                              {" الهاتف "} {order1.phoneNum}
                            </p>
                            <div>
                              <p> {order1.videoname}</p>{" "}
                              <span>السعر {order1.price}جنيه</span>
                            </div>
                            {/* <button
                            className="btn btn8"
                            onClick={() =>
                              giveAccess(order1.userId, order1.videoId)
                            }
                          >
                            اعطي الاذن
                          </button> */}
                            <button
                              type="button"
                              className="btn btn8"
                              data-bs-toggle="modal"
                              data-bs-target={`#l${order1._id}`}>
                              اعطي الاذن
                            </button>

                            <div
                              className="modal fade"
                              id={`l${order1._id}`}
                              tabIndex="-1"
                              aria-labelledby={`l${order1._id}Label`}
                              aria-hidden="true">
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id={`l${order1._id}`}>
                                      اعطاء الاذن{" "}
                                    </h5>
                                  </div>
                                  <div className="modal-body">
                                    <p>
                                      هل انت متأكد برغبتك في اعطاء الاذن للطالب{" "}
                                      {order1.username}
                                    </p>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal">
                                      الغاء
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-primary "
                                      data-bs-dismiss="modal"
                                      onClick={() =>
                                        giveAccess(
                                          order1.userId,
                                          order1.videoId,
                                          order1._id
                                        )
                                      }>
                                      اعطي الاذن
                                      {order1.videoId.length}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/*delete order */}
                            <button
                              type="button"
                              className="btn btn-danger del2"
                              data-bs-toggle="modal"
                              data-bs-target={`#m${order1._id}`}>
                              حذف
                            </button>

                            <div
                              className="modal fade"
                              id={`m${order1._id}`}
                              tabIndex="-1"
                              aria-labelledby={`m${order1._id}Label`}
                              aria-hidden="true">
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id={`m${order1._id}`}>
                                      حذف الطلبات{" "}
                                    </h5>
                                  </div>
                                  <div className="modal-body">
                                    <p>
                                      هل انت متأكد برغبتك في حذف طلب التلميذ{" "}
                                      {order1.username}
                                    </p>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal">
                                      الغاء
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger "
                                      data-bs-dismiss="modal"
                                      onClick={() => deleteRequest(order1._id)}>
                                      حذف
                                      {order1.videoId.length}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          {errMsg ? (
                            <h3 className="container alert alert-danger">
                              {errMsg}
                            </h3>
                          ) : null}
                        </>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <div className="alert alert-success">
                  ليس هناك طلبات انتظر قليلا
                </div>
              )}
            </div>
          </div>
        ) : (
          <h1 className="alert alert-danger mt-5">عفوا انت لست ادمن</h1>
        )
      ) : (
        <h1 className="alert alert-danger mt-5">يجب عليك تسجيل الدخول اولا</h1>
      )}

      <footer>حقوق الطبع محفوظة لصالح استاذ &copy;معاذ</footer>
    </div>
  );
};

export default AdminDashboard;
