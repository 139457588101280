import React, { useState, useContext, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../context/users/authContext";

const SubjectPdfDetails = () => {
  const { auth } = useContext(AuthContext);
  const [searchParams, setSearchPrams] = useSearchParams();
  const [file, setFile] = useState([]);
  let [deleted, setDeleted] = useState(false);
  console.log(file);
  //getting queries from url
  let kind = searchParams.get("kind");
  let chapter = searchParams.get("chapter");
  let type = searchParams.get("type");

  //get pdf from db on load
  useEffect(() => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let body = { kind, chapter, type };
    //post to database
    axios
      .post("/v1/vedios", body, config)
      .then((res) => {
        setFile(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [deleted]);

  //delete request to database
  const DeletePdf = (pdfId) => {
    let token = auth.token;
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    //check token and add to headers
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    //post to database
    let data = { pdfId };
    axios
      .post("/v1/vedios/delete-pdf", data, config)
      .then((res) => {
        console.log(res);
        setDeleted((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="subject-pdf-details">
      <div className="pdf-header header">
        <h3 className="display-4 pt-5 text-center">تفاصيل التمارين</h3>
        <p className="text-center">
          <Link className="sub-H" to="/">
            الرئيسية
          </Link>{" "}
          /{" "}
          <Link className="sub-H" to="/subjects-pdf">
            المواد
          </Link>
        </p>
      </div>

      <div className="container pdf-body">
        <div className="row">
          {file.length ? (
            file.map((file1) => {
              return (
                <div className="col-lg-4 col-md-5 col-sm-12" key={file1._id}>
                  <div className="card text-center pdf-D-card">
                    <div className="card-header">
                      {file1.subject} {"باب"} {file1.chapter}{" "}
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{file1.subName} </h5>
                      <p className="card-text"> {file1.details} </p>
                      <Link
                        to={`/subject-show-PDF?link=${file1.link}&subName=${file1.subName}`}
                      >
                        <button className="btn btn8">تصفح ال pdf</button>
                      </Link>
                      {auth.isAuthenticated ? (
                        auth.user.role === "admin" ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-danger delete-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                              حذف
                            </button>

                            <div
                              className="modal fade"
                              id="exampleModal"
                              tabIndex="-1"
                              aria-labelledby="exampleModalLabel"
                              aria-hidden="true"
                            >
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id="exampleModal"
                                    >
                                      حذف الpdf{" "}
                                    </h5>
                                  </div>
                                  <div className="modal-body">
                                    <p>هل انت متأكد برغبتك في حذف ال pdf</p>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal"
                                    >
                                      الغاء
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger "
                                      data-bs-dismiss="modal"
                                      onClick={() => DeletePdf(file1._id)}
                                    >
                                      حذف
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null
                      ) : null}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <h3 className="alert alert-success">لايوجد تمارين انتظر قليلا </h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubjectPdfDetails;
