import React from "react";
import { useSearchParams } from "react-router-dom";

const SubjectShowPdf = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  let link = searchParams.get("link");
  let subName = searchParams.get("subName");

  return (
    <div>
      <div className="pdf-header headerPdf">
        <h3 className="display-4 pt-5 text-center"> درس {subName}</h3>
      </div>

      <div className="container pdf-cont">
        <div>
          <object
            data={`/v1/vedios/stream-pdf?link=${link}`}
            type="application/pdf"
            className="pdf"
          >
            <div className="text-center">
              <h4>
                المتصفح الخاص بك لا يدعم عرض ال pdf لكن يمكنك التحميل من الزر
                ادناه
              </h4>
              <a href={`/pdfs/${link}.pdf`} className="btn btn8 pdf-a" download>
                اضغط لتحميل ال pdf
              </a>
            </div>{" "}
          </object>
        </div>
      </div>
      <footer>حقوق الطبع محفوظة لصالح استاذ &copy;معاذ</footer>
    </div>
  );
};

export default SubjectShowPdf;
