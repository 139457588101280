import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./pdf.css";
import Aos from "aos";

const SubjectPdf = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div className="subject-pdf">
      <div className="pdf-header header">
        <h3 className="display-4 pt-5 text-center">المطبوعات والتمارين</h3>
        <p className="text-center">
          <Link className="sub-H" to="/">
            الرئيسية
          </Link>{" "}
          /{" "}
          <Link className="sub-H" to="/subjects-pdf">
            المواد
          </Link>
        </p>
      </div>

      <div className="container pdf-body">
        <div className="row">
          {/* card-1 */}
          <div className="col-12">
            <div className="custom-card">
              <div className="cust-H">
                <h3 className="display-7">
                  رياضيات الصف الثالث باب الاحتمالات
                </h3>{" "}
              </div>
              <div className="cust-B">
                <h4 className="pt-3">تصفح امثلة و تمارين الباب</h4>
                <Link
                  to={
                    "/subject-pdf-details?kind=علمي&chapter=الاحتمالات&type=.pdf"
                  }
                >
                  <button className="btn btn8">التفاصيل</button>
                </Link>
              </div>
            </div>
          </div>

          {/* card-2 */}
          <div className="col-12">
            <div data-aos="fade-right" className="custom-card">
              <div className="cust-H">
                <h3 className="display-7">رياضيات الصف الثالث باب الاحصاء</h3>{" "}
              </div>
              <div className="cust-B">
                <h4 className="pt-3">تصفح امثلة و تمارين الباب</h4>
                <Link
                  to={
                    "/subject-pdf-details?kind=علمي&chapter=الاحصاء&type=.pdf"
                  }
                >
                  <button className="btn btn8">التفاصيل</button>
                </Link>
              </div>
            </div>
          </div>

          {/* card-3 */}
          <div className="col-12">
            <div data-aos="fade-right" className="custom-card">
              <div className="cust-H">
                <h3 className="display-7">
                  رياضيات الصف الثالث باب الاعداد مركبة
                </h3>{" "}
              </div>
              <div className="cust-B">
                <h4 className="pt-3">تصفح امثلة و تمارين الباب</h4>
                <Link
                  to={
                    "/subject-pdf-details?kind=علمي&chapter=الاعداد المركبة&type=.pdf"
                  }
                >
                  <button className="btn btn8">التفاصيل</button>
                </Link>
              </div>
            </div>
          </div>

          {/* card-4 */}
          <div className="col-12">
            <div data-aos="fade-right" className="custom-card">
              <div className="cust-H">
                <h3 className="display-7">رياضيات الصف الثالث باب التفاضل</h3>{" "}
              </div>
              <div className="cust-B">
                <h4 className="pt-3">تصفح امثلة و تمارين الباب</h4>
                <Link
                  to={
                    "/subject-pdf-details?kind=علمي&chapter=التفاضل&type=.pdf"
                  }
                >
                  <button className="btn btn8">التفاصيل</button>
                </Link>
              </div>
            </div>
          </div>

          {/* card-5 */}
          <div className="col-12">
            <div data-aos="fade-right" className="custom-card">
              <div className="cust-H">
                <h3 className="display-7">رياضيات الصف الثالث باب التكامل</h3>{" "}
              </div>
              <div className="cust-B">
                <h4 className="pt-3">تصفح امثلة و تمارين الباب</h4>
                <Link
                  to={
                    "/subject-pdf-details?kind=علمي&chapter=التكامل&type=.pdf"
                  }
                >
                  <button className="btn btn8">التفاصيل</button>
                </Link>
              </div>
            </div>
          </div>

          {/* card-6 */}
          <div className="col-12">
            <div data-aos="fade-right" className="custom-card">
              <div className="cust-H">
                <h3 className="display-7">رياضيات الصف الثالث باب الدائرة</h3>{" "}
              </div>
              <div className="cust-B">
                <h4 className="pt-3">تصفح امثلة و تمارين الباب</h4>
                <Link
                  to={
                    "/subject-pdf-details?kind=علمي&chapter=الدائرة&type=.pdf"
                  }
                >
                  <button className="btn btn8">التفاصيل</button>
                </Link>
              </div>
            </div>
          </div>

          {/* card-7 */}
          <div className="col-12">
            <div data-aos="fade-right" className="custom-card">
              <div className="cust-H">
                <h3 className="display-7">
                  رياضيات الصف الثالث باب الدوال الحقيقية والنهايات
                </h3>{" "}
              </div>
              <div className="cust-B">
                <h4 className="pt-3">تصفح امثلة و تمارين الباب</h4>
                <Link
                  to={"/subject-pdf-details?kind=علمي&chapter=الدوال&type=.pdf"}
                >
                  <button className="btn btn8">التفاصيل</button>
                </Link>
              </div>
            </div>
          </div>

          {/* card-8 */}
          <div className="col-12">
            <div data-aos="fade-right" className="custom-card">
              <div className="cust-H">
                <h3 className="display-7">رياضيات الصف الثالث باب الكسور</h3>{" "}
              </div>
              <div className="cust-B">
                <h4 className="pt-3">تصفح امثلة و تمارين الباب</h4>
                <Link
                  to={"/subject-pdf-details?kind=علمي&chapter=الكسور&type=.pdf"}
                >
                  <button className="btn btn8">التفاصيل</button>
                </Link>
              </div>
            </div>
          </div>

          {/* card-9 */}
          <div className="col-12">
            <div data-aos="fade-right" className="custom-card">
              <div className="cust-H">
                <h3 className="display-7">رياضيات الصف الثالث باب المصفوفات</h3>{" "}
              </div>
              <div className="cust-B">
                <h4 className="pt-3">تصفح امثلة و تمارين الباب</h4>
                <Link
                  to={
                    "/subject-pdf-details?kind=علمي&chapter=المصفوفات&type=.pdf"
                  }
                >
                  <button className="btn btn8">التفاصيل</button>
                </Link>
              </div>
            </div>
          </div>

          {/* card-10 */}
          <div className="col-12">
            <div data-aos="fade-right" className="custom-card">
              <div className="cust-H">
                <h3 className="display-7">رياضيات الصف الثالث باب مبدأ العد</h3>{" "}
              </div>
              <div className="cust-B">
                <h4 className="pt-3">تصفح امثلة و تمارين الباب</h4>
                <Link
                  to={
                    "/subject-pdf-details?kind=علمي&chapter=مبدأ العد&type=.pdf"
                  }
                >
                  <button className="btn btn8">التفاصيل</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer>حقوق الطبع محفوظة لصالح استاذ &copy;معاذ</footer>
    </div>
  );
};

export default SubjectPdf;
