import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const MathSecond = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div data-aos="fade-up" className="row revLessons">
      {/* <!--card1--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الثاني&chapter=الهندسة التحليلية&type=video/mp4">
            <img
              src="./images/subject.jpg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p>الهندسة التحليلية</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الثاني&chapter=الهندسة التحليلية&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card2--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الثاني&chapter=الدالة الاسية&type=video/mp4">
            <img
              src="./images/subject.jpg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p>الدالة الاسية</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الثاني&chapter=الدالة الاسية&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card3--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الثاني&chapter=الدالة اللوغريثمية&type=video/mp4">
            <img
              src="./images/subject.jpg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> الدالة اللوغريثمية</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الثاني&chapter=الدالة اللوغريثمية&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card4--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الثاني&chapter=الجذور الصم&type=video/mp4">
            <img
              src="./images/subject.jpg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> الجذور الصم</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الثاني&chapter=الجذور الصم&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card5--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الثاني&chapter=حساب مثلثات&type=video/mp4">
            <img
              src="./images/subject.jpg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> حساب مثلثات</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الثاني&chapter=حساب مثلثات&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card6--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الثاني&chapter=المتتاليات&type=video/mp4">
            <img
              src="./images/subject.jpg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> المتتاليات</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الثاني&chapter=المتتاليات&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card7--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الثاني&chapter=المتباينات&type=video/mp4">
            <img
              src="./images/subject.jpg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> المتباينات</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الثاني&chapter=المتباينات&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card8--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الثاني&chapter=نظرية الباقي و العامل&type=video/mp4">
            <img
              src="./images/subject.jpg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> نظرية الباقي و العامل</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الثاني&chapter=نظرية الباقي و العامل&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card9--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الثاني&chapter=مجموعة الاعداد المركبة&type=video/mp4">
            <img
              src="./images/subject.jpg"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p>مجموعة الاعداد المركبة </p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الثاني&chapter=مجموعة الاعداد المركبة&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
    </div>
  );
};

export default MathSecond;
