import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../context/users/authContext";
import { ErrContext } from "../../context/users/errContext";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";

const Login = () => {
  //context state
  const { auth, SignINUser, SignOutUser } = useContext(AuthContext);
  const { err, GetErrors, ClearErrors } = useContext(ErrContext);
  //local state
  let [username, setUsername] = useState("");
  let [phoneNum, setPhoneNum] = useState("");
  const [isloading, setIsloading] = useState(false);

  // let[msg, setMsg] = useState('')

  //animation package
  useEffect(() => {
    Aos.init({ duration: 900 });
  }, []);

  const handleSubmit = (e) => {
    setIsloading(true);

    e.preventDefault();
    //headers and body
    const config = {
      headers: {
        "content-type": "application/json",
      },
    };
    const User = { username, phoneNum };
    const body = JSON.stringify(User);
    //axios to server
    axios
      .post("/users/auth", body, config)
      .then((res) => {
        setIsloading(false);
        SignINUser(res.data);
        ClearErrors();
      })
      .catch((err) => {
        setIsloading(false);
        GetErrors(err.response.data, err.response.status, "login_err");
        SignOutUser();
      });
  };

  return (
    <div className="login">
      <div className="section">
        <div data-aos="fade-up" className="box mt-5">
          <form action="" onSubmit={handleSubmit}>
            <h1 className="display-5 text-center">تسجيل الدخول </h1>
            {isloading ? (
              <div className="row">
                <div className="col-12">
                  <div className="spinner-cont">
                    <div className="spinner-loader"></div>
                  </div>
                </div>
              </div>
            ) : null}
            {err.id === "login_err" ? (
              <div className="errMsg">{err.msg.msg}</div>
            ) : null}
            {auth.token ? (
              <div className="alert alert-success">
                <span> تم تسجيل الدخول بنجاح </span> <br />
                <Link className="btn btn8" to="/subjects">
                  {" "}
                  تصفح المواد
                </Link>
              </div>
            ) : null}
            <div className="form-group">
              <label className="label">اسم المستخدم </label>
              <input
                type="text"
                className="form-control"
                placeholder="ادخل اسم المستخدم"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>{" "}
            <br />
            <div className="form-group">
              <label className="label">رقم الهاتف</label>
              <input
                type="number"
                className="form-control"
                placeholder="ادخل رقم الهاتف"
                value={phoneNum}
                onChange={(e) => setPhoneNum(e.target.value)}
              />
            </div>
            <br />
            <div className="text-center">
              <p>OR</p>
              {/* <button className="btn btn7 ">
                {" "}
                <img
                  src="./images/facebok-logo.png"
                  className="contact-img"
                  alt="facebok-logo"
                />{" "}
                سجل عن طريق فيسبوك{" "}
              </button> */}
              {/* <br /> <br /> */}
              <p>
                ليس لديك حساب ؟ <Link to={"/signup"}>سجل الان</Link>
              </p>
              <button className="btn btn3">ارسل</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
