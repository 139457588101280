import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../../context/users/authContext";

const ViewBlog = () => {
  const [blogData, setBlogData] = useState([]);
  const { auth } = useContext(AuthContext);
  const [isloading, setIsloading] = useState(false);
  const [deleted, setDeleted] = useState(false);

  useEffect(() => {
    setIsloading(true);

    axios
      .get("/v1/blog/get-data")
      .then((res) => {
        setIsloading(false);
        setBlogData(res.data.blogs);
      })
      .catch((err) => {
        setIsloading(false);
        console.log(err);
      });
  }, [deleted]);

  const deleteBlog = (adminId, blogId) => {
    setIsloading(true);

    let data = { blogId, adminId };

    axios
      .post("/v1/blog/delete-blog", data)
      .then((res) => {
        setIsloading(false);
        setDeleted(true);
      })
      .catch((err) => {
        setIsloading(false);
        console.log(err);
      });
  };

  return (
    <>
      {isloading ? (
        <div className="loader-container">
          <div className="spinner-container">
            <div className="loading-spinner"></div>
          </div>
        </div>
      ) : null}
      {blogData
        ? blogData.length
          ? blogData.map((blog) => {
              return (
                <div className="view-blog" key={blog._id}>
                  <hr />
                  <h3 className="mb-3">{blog.blogText}</h3>
                  {isloading ? (
                    <div className="text-center">
                      <div
                        className="spinner-grow  text-primary"
                        style={{ width: "3rem", height: "3rem" }}
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : null}
                  {deleted && (
                    <h5 className="container alert alert-success">
                      تم الحذف بنجاح
                    </h5>
                  )}
                  {auth.user && auth.user.username === "muaz@admin" && (
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteBlog(auth.user._id, blog._id)}
                    >
                      احذف الاعلان
                    </button>
                  )}
                  <br />
                  <br />
                  {blog.type === "image/*" ? (
                    <img
                      className="adv-img2"
                      src={`./images/${blog.ImgLink}`}
                      alt="advertise"
                    />
                  ) : (
                    <video
                      width="700"
                      height="500"
                      controls
                      controlsList="nodownload"
                      id="videoTag"
                      className="adv-img2"
                      onContextMenu={(e) => e.preventDefault()}
                    >
                      <source
                        src={`/v1/blog/stream-video?link=${blog.ImgLink}`}
                        type="video/mp4"
                      />
                    </video>
                  )}
                </div>
              );
            })
          : null
        : null}
    </>
  );
};

export default ViewBlog;
