import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/users/authContext";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Aos from "aos";
import "aos/dist/aos.css";

const UserDashboard = () => {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isloading, setIsloading] = useState(false);

  const [skip, setSkip] = useState(0);
  const [video, setVideo] = useState([]);
  const [err, setErr] = useState("");
  const [meetingNumber, setMeetingNumber] = useState("");
  console.log(err);
  console.log(skip);

  useEffect(() => {
    //animation package
    Aos.init({ duration: 1500 });

    //set loading here
    setIsloading(true);

    let token = auth.token;

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (token) {
      config.headers["x-auth-token"] = token;
    }
    if (auth.user) {
      let videosId = [];
      videosId = auth.user.videosId;
      const data = { videosId, skip };
      console.log(data);

      axios
        .post("/v1/vedios/user-videos", data, config)
        .then((res) => {
          console.log(res.data);
          setVideo(res.data);
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
          setErr(err.response.data);
          setIsloading(false);
        });
    }
  }, [skip]);

  //useeffect to check if there is a meeting id
  useEffect(() => {
    //call the end point
    if (auth.user) {
      let userId = auth.user._id;
      let data = { userId };
      axios
        .post("/v1/joinZoom/get-credentials", data)
        .then((res) => {
          setMeetingNumber(res.data.meetingNumber);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const ZoomAccess = (userId) => {
    navigate(`/getzoom/join/${userId}`);
  };

  return (
    <div className="user-dashboard">
      <div className="container-fluid header">
        <div className="text-center U-header">
          {auth.user && (
            <>
              <h4 className="display-6">مرحبا {auth.user.username}</h4>

              <h3 className="display-5 pt-3 "> شاهد الفيديوهات الخاصة بك</h3>
              <br />
              {auth.user.role === "apptojoinzoom" && (
                <>
                  {meetingNumber && (
                    <button
                      className="btn btn-primary"
                      onClick={() => ZoomAccess(auth.user._id)}
                    >
                      انضم للاجتماع الان
                    </button>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      {auth.isAuthenticated ? (
        err ? (
          <>
            <h3 className="alert alert-success mt-5">{err} </h3> <br /> <br />
            <div className="text-center">
              <Link to="/subjects" className="btn btn8 btn-lg mb-5">
                اطلب المزيد
              </Link>
            </div>
          </>
        ) : (
          <div className="u-body">
            {video.length ? (
              <div className="row">
                {video.map((userVideo) => {
                  return (
                    <div
                      className="col-lg-4 col-md-5 col-sm-12"
                      key={userVideo._id}
                    >
                      {/* loading here */}
                      {isloading ? (
                        <div className="row">
                          <div className="col-12">
                            <div className="spinner-cont">
                              <div className="spinner-loader"></div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="card">
                        <div className="card-header">
                          {userVideo.subject} {" باب"} {userVideo.chapter}{" "}
                          {userVideo.booknum}
                        </div>
                        <div className="card-body video-body">
                          <h5 className="card-title">{userVideo.subName} </h5>
                          <p className="card-text">{userVideo.details}</p>
                          <Link
                            to={`/users-details?id=${userVideo._id}`}
                            className="btn btn-primary"
                          >
                            شاهد الفيديو
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  {" "}
                  <>
                    <div className="display-7 navigate">
                      {/* <button
                        className="btn btn-outline-primary mb-5"
                        onClick={() => setSkip(skip + 10)}
                      >
                        المزيد ^^
                      </button>
                      <button
                        className="btn btn-outline-danger mb-5 re-btn"
                        onClick={() => setSkip(skip - 10)}
                      >
                        رجوع ^^
                      </button> */}
                      <div aria-label="Page navigation example  text-center">
                        <ul className="pagination">
                          <li className="page-item">
                            <div className="page-link" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                              <span
                                className="sr-only"
                                onClick={() => setSkip(skip - 10)}
                              >
                                السابق
                              </span>
                            </div>
                          </li>
                          <li className="page-item">
                            <span
                              className="page-link"
                              onClick={() => setSkip(10)}
                            >
                              1
                            </span>
                          </li>
                          <li className="page-item">
                            <span
                              className="page-link"
                              onClick={() => setSkip(20)}
                            >
                              2
                            </span>
                          </li>
                          <li className="page-item">
                            <span
                              className="page-link"
                              onClick={() => setSkip(30)}
                            >
                              3
                            </span>
                          </li>
                          <li className="page-item">
                            <div className="page-link" aria-label="Next">
                              <span aria-hidden="true">&raquo;</span>
                              <span
                                className="sr-only"
                                onClick={() => setSkip(skip + 10)}
                              >
                                التالي
                              </span>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>{" "}
                  </>
                </div>
              </div>
            ) : (
              <h3 className="alert alert-success">
                لايوجد لديك فيديوهات الان اطلب لتحصل على الفيديوهات
              </h3>
            )}
          </div>
        )
      ) : (
        <h1 className="alert alert-danger u-body">
          يجب عليك تسجيل الدخول لمشاهدة الفيديوهات
        </h1>
      )}
      <footer>حقوق الطبع محفوظة لصالح استاذ &copy;معاذ</footer>
    </div>
  );
};

export default UserDashboard;
