import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import ViewBlog from "../blog/viewBlogs";

const HomePage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const [msg, setMsg] = useState("");
  console.log(msg);
  const handlSubmit = (e) => {
    e.preventDefault();

    let data = { name, email, text };
    //post to database
    axios
      .post("/email", data)
      .then((res) => {
        setMsg(res.data);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);
  return (
    <div className="homepage">
      {/* <!--header starts here-->     */}
      <header>
        <div className=" cont1">
          <div className="div1 row">
            <div className="col-md-7 col-sm-12">
              <div data-aos="fade-left" className="header-text">
                <h1 className="display-5">
                  {" "}
                  منصة استاذ <span className="h1-txt">معاذ</span> لطلاب الشهادة
                  الثانوية
                </h1>
                <h4 className="sub-header-txt"> الاولي من نوعها في السودان </h4>
                <h3 className="new-head">
                  للمعلومات حول الحصص المباشرة
                  <a className="btn btn1" href="#advertise1">
                    {" "}
                    ابدأ الان{" "}
                  </a>
                </h3>
                <h3 className="new-head2">
                  للمزيد
                  <Link className="btn btn1-1 " to="/subjects">
                    {" "}
                    تصفح المواد
                  </Link>
                </h3>
              </div>
            </div>
            <div className="col-md-5 col-sm-12 img-wrapper">
              <div data-aos="fade-right" className="header-right ">
                <img className="img1" src="./images/3-rm.png" alt="" />
              </div>
            </div>
          </div>

          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#fff"
              fillOpacity="1"
              d="M0,96L40,96C80,96,160,96,240,101.3C320,107,400,117,480,133.3C560,149,640,171,720,154.7C800,139,880,85,960,69.3C1040,53,1120,75,1200,74.7C1280,75,1360,53,1400,42.7L1440,32L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
          </svg>
        </div>
      </header>
      {/* <!--header ends here--> */}

      {/* special blog here */}
      <ViewBlog />
      {/* special blog here */}

      {/* <!--contact us section--> */}
      <div className="cont4" id="advertise1">
        <h3 className=" text-center ">
          {" "}
          <span className="cl-text">الان </span> يمكنك الاشتراك في حصص استاذ
          معاذ المباشرة على الموقع الالكتروني
        </h3>
        <div className="wrapper">
          <div data-aos="zoom-in" data-aos-duration="900">
            <div className="row">
              <div className="col-12">
                <img
                  className="adv-img"
                  src="./images/advertise1.jpg"
                  alt="advertise"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--contact us end here--> */}

      {/* <!--about us section--> */}
      <div id="aboutus" className="container cont2">
        <div className="row">
          <div className="col-lg-7 col-md-6 col-sm-12">
            <div data-aos="fade-left" className="about-text">
              <h1 className="text-center display-5">
                {" "}
                عن <span className="cl-text">المنصة؟ </span>{" "}
              </h1>
              <br />
              <h3>
                <div>
                  <span className="bold">
                    مركز استاذ معاذ لطلاب الشهادة الثانوية{" "}
                  </span>
                  مركز رائد في تدريس طلاب الشهادة الثانوية مادة الرياضيات كما
                  يحتوي المركز على مجموعات خاصة في جميع المواد . تأسس المركز عام
                  2007 على يد استاذ معاذ عبدالقادر وهو احد الاساتذة الرواد في
                  تدريس مادة الرياضيات لجميع صفوف المرحلة الثانوية.
                </div>
                <p className="extra-info">
                  يقع المركز في امدرمان - الملازمين جوار محلات الفائز للاثاثات.
                </p>
              </h3>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div data-aos="fade-right" className="img2">
              <img src="./images/about2.webp" alt="" />
            </div>
          </div>
        </div>
      </div>
      {/* <!--about us end here--> */}

      {/* adviertise section*/}
      <div id="advertise" className="advertise">
        <div className="container cont-adv">
          <div data-aos="fade-down" data-aos-duration="900">
            <div className="row">
              <div className="col-12">
                <img
                  className="adv-img2"
                  src="./images/advertise2.jpg"
                  alt="advertise"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--categories section--> */}
      <div className="container cont3">
        <h2 className="display-5 text-center">
          <span className="cl-text">اهم</span> ما نقدمه
        </h2>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div data-aos="fade-left" className="circle">
              <span className="cat-img">
                <img className="img4" src="./images/logo2.png" alt="" />
              </span>
              <p className="cat-text">مطبوعات pdf لكل الحصص و التمارين </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div data-aos="fade-down" className="circle">
              <span className="cat-img">
                <img
                  className="img4"
                  src="./images/PicsArt_logo4-mod.png"
                  alt=""
                />
              </span>
              <p className="cat-text">
                شرح تفصيلي لمقررات الرياضيات للشهادة الثانوية{" "}
              </p>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <div data-aos="fade-right" className="circle">
              <span className="cat-img">
                <img className="img4" src="./images/PicsArt_logo3.png" alt="" />
              </span>
              <p className="cat-text">
                حصص المراجعة لطلاب الشهادة الثانوية وقريبا مراجعة للصفين الاول و
                الثاني{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!--categories section ends here--> */}

      {/*MBOK section for payment clarity */}
      <section className="container-fluid bannar-section text-center">
        <div className="bannar-caption">
          {/* <img src="./images/1." className="mb-3" width="120px" alt="" /> */}
          <h3> يمكنك الدفع عن طريق بنكك عبر الحساب التالي</h3>

          <p className="text-center">
            2916188 <br /> <span> الاسم: معاذ عبدالقادر </span>
          </p>
          <br />
          {/* <Link to="/contact" className="btn btn8">
            كن على تواصل
          </Link> */}
        </div>
      </section>
      {/*MBOK section end here */}

      {/* <!--footer starts here--> */}
      <footer>حقوق الطبع محفوظة لصالح استاذ &copy;معاذ</footer>
    </div>
  );
};

export default HomePage;
