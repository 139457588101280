import React, { useEffect } from "react";
import "./zoom.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";

import { keys } from "../impkeys";

const Zoom = () => {
  let id = useParams();
  const client = ZoomMtgEmbedded.createClient();

  useEffect(() => {
    //first call the data base to get the data
    axios
      .post("/v1/joinZoom/getLink", id)
      .then((res) => {
        //then join the meeting
        // if (cancel) return;
        JoinMeeting(
          res.data.signature,
          res.data.currentLink,
          res.data.username,
          res.data.passcode
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const JoinMeeting = (signature, meetingNumber, username, passcode) => {
    let meetingSDKElement = document.getElementById("meetingSDKElement");
    //initialize meeting
    client.init({
      debug: true,
      zoomAppRoot: meetingSDKElement,
      language: "en-US",
      customize: {
        video: {
          isResizable: true,
          viewSizes: {
            default: {
              width: 340,
              height: 300,
            },
            ribbon: {
              width: 300,
              height: 700,
            },
          },
        },
        meetingInfo: [
          "topic",
          "host",
          "mn",
          "pwd",
          "telPwd",
          "invite",
          "participant",
          "dc",
          "enctype",
        ],
        toolbar: {
          buttons: [
            {
              text: "leave now",
              className: "CustomButton",
              onClick: () => {
                console.log("custom button");
              },
            },
          ],
        },
      },
    });
    //join meeting
    //before joining the meeting checking if the user is admin or not
    let UserName;
    if (username === "muaz@admin") {
      UserName = "website admin";
    } else {
      UserName = username;
    }

    client.join({
      sdkKey: keys.sdkkey,
      signature: signature, // role in SDK Signature needs to be 0
      meetingNumber: meetingNumber,
      password: passcode,
      userName: UserName,
      success: (success) => {
        console.log("success");
      },
      error: (err) => {
        console.log(err);
      },
    });
  };

  return (
    <div className="Zoom">
      <h4 className="text-center"> حصص استاذ معاذ المباشرة</h4>
      <div className="JoinZoom2">
        <div id="meetingSDKElement"></div>
      </div>
    </div>
  );
};

export default Zoom;
