import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const RevisionLessons = () => {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  });
  return (
    <div data-aos="fade-up" className="row revLessons Rev">
      <h2 className="text-center mb-4">حصص المراجعة</h2>

      {/* <!--card1--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/revision-name?kind=حصص مراجعة&booknum=الكتاب الأول&type=video/mp4">
            <img
              src="./images/subject2.png"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> الرياضيات المتخصصة (1)</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/revision-name?kind=حصص مراجعة&booknum=الكتاب الأول&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card2--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/revision-name?kind=حصص مراجعة&booknum=الكتاب الثاني&type=video/mp4">
            <img
              src="./images/subject2.png"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p>الرياضيات المتخصصة (2)</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/revision-name?kind=حصص مراجعة&booknum=الكتاب الثاني&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card3--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/revision-name?kind=حصص مراجعة&booknum=كتاب الأدبي&type=video/mp4">
            <img
              src="./images/subject2.png"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> الرياضيات الاساسية</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/revision-name?kind=حصص مراجعة&booknum=كتاب الأدبي&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
    </div>
  );
};

export default RevisionLessons;
