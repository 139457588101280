import React, { useContext, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/homepage/HomePage";
import Subjects from "./components/subjects/third-class/Subjects";
import Login from "./components/users-utilities/login";
import SignUp from "./components/users-utilities/signUp";
import SubjectsDetails from "./components/subjects/subjects-details";
import { AuthContext } from "./context/users/authContext";
import AdminDashboard from "./components/dashbord/adminDashbord";
import axios from "axios";
import SubjectsName from "./components/subjects/subject-name";
import Navbar from "./components/Navbar/Navbar";
import UserDashboard from "./components/dashbord/user-dashboard";
import SearchResult from "./components/Navbar/searchResult";
import SubjectPdf from "./components/subjects-pdf/Subject-pdf";
import SubjectPdfDetails from "./components/subjects-pdf/subject-pdf-details";
import SubjectShowPdf from "./components/subjects-pdf/subject-showPDF";
import RevisionName from "./components/subjects/revision Lesson/revision-name";
import SubjectsName2 from "./components/subjects/first-second/subject-name2";
import UserDetails from "./components/dashbord/user-details";
import Zoom from "./components/zoom";

function App() {
  const { auth, LoadUser, SignOutUser } = useContext(AuthContext);
  const token = auth.token;

  const fetchUser = () => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    //check token existence
    if (token) {
      config.headers["x-auth-token"] = token;
    }

    //call to db
    axios
      .get("/users/auth/get-user", config)
      .then((res) => {
        LoadUser(res.data);
      })
      .catch((err) => {
        console.log(err);
        SignOutUser();
      });
  };

  useEffect(() => {
    fetchUser();
  }, [token]);

  return (
    <div className="app">
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />

        <Route path="/subjects" element={<Subjects />} />

        <Route path="/subjects-pdf" element={<SubjectPdf />} />

        <Route path="/subjects-name" element={<SubjectsName />} />

        <Route path="/subjects-name2" element={<SubjectsName2 />} />

        <Route path="/revision-name" element={<RevisionName />} />

        <Route path="/subjects-details" element={<SubjectsDetails />} />

        <Route path="/users-details" element={<UserDetails />} />

        <Route path="/subject-pdf-details" element={<SubjectPdfDetails />} />

        <Route path="/subject-show-PDF" element={<SubjectShowPdf />} />

        <Route path="/login" element={<Login />} />

        <Route path="/signup" element={<SignUp />} />

        <Route path="/admin-dashboard" element={<AdminDashboard />} />

        <Route path="/user-dashboard" element={<UserDashboard />} />

        <Route path="/search-result" element={<SearchResult />} />

        <Route path="/getzoom/join/:id" element={<Zoom />} />
      </Routes>
    </div>
  );
}

export default App;
