import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const MathFirst = () => {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div data-aos="zoom-in" className="row revLessons">
      {/* <!--card1--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الاول&chapter=المنطق الرياضي&type=video/mp4">
            <img
              src="./images/subject.webp"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p>المنطق الرياضي</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الاول&chapter=المنطق الرياضي&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!-- card2 --> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الاول&chapter=الدوال المثلثية&type=video/mp4">
            <img
              src="./images/subject.webp"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> الدوال المثلثية</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الاول&chapter=الدوال المثلثية&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!-- card3 --> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الاول&chapter=المجموعات&type=video/mp4">
            <img
              src="./images/subject.webp"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> المجموعات</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الاول&chapter=المجموعات&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!-- card4 --> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الاول&chapter=العلاقات&type=video/mp4">
            <img
              src="./images/subject.webp"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> العلاقات</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الاول&chapter=العلاقات&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!-- card5 --> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الاول&chapter=التغيير&type=video/mp4">
            <img
              src="./images/subject.webp"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> التغيير</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الاول&chapter=التطبيق&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!-- card6 --> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الاول&chapter=الهندسة التحليلية&type=video/mp4">
            <img
              src="./images/subject.webp"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> الهندسة التحليلية</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الاول&chapter=الهندسة التحليلية&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!-- card7 --> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name2?kind=الصف الاول&chapter=كثيرات الحدود&type=video/mp4">
            <img
              src="./images/subject.webp"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> كثيرات الحدود</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name2?kind=الصف الاول&chapter=كثيرات الحدود&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
    </div>
  );
};

export default MathFirst;
