import React, { useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { AuthContext } from "../../../context/users/authContext";

const RevisionName = () => {
  const { auth } = useContext(AuthContext);
  const [revLesson, setRevLesson] = useState([]);
  const [datdeleted, setDatdeleted] = useState(false);
  let [ordered, setOrdered] = useState(false);
  const [searchParams, setSearchPrams] = useSearchParams();
  let [isloading, setIsloading] = useState(false);
  let [errMsg, setErrMsg] = useState("");

  let kind = searchParams.get("kind");
  let booknum = searchParams.get("booknum");
  let type = searchParams.get("type");

  useEffect(() => {
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let data = { kind, booknum, type };
    //post data to db
    axios
      .post("/v1/vedios/revision", data, config)
      .then((res) => {
        setRevLesson(res.data.data);
      })
      .catch((err) => console.log(err));
  }, [datdeleted, ordered]);

  //function to delete video
  const deletVideo = (videoId) => {
    let token = auth.token;

    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    let data = { videoId };
    axios
      .post("/v1/vedios/delete-video", data, config)
      .then((res) => setDatdeleted((prev) => !prev))
      .catch((err) => console.log(err));
  };

  //function for multiple orders
  const multOrder = (kind, booknum, price, userId) => {
    setIsloading(true);

    let data = { kind, booknum, price, userId };

    axios
      .post("/v1/orders/revision-order", data)
      .then((res) => {
        console.log(res);
        setOrdered(true);
        setIsloading(false);
      })
      .catch((err) => {
        setErrMsg(err.response.data);
        console.log(err);
      });
  };

  return (
    <div className="rev-name">
      {/* <!--header--> */}
      <div className="container-fluid cont6">
        <div className="text-center">
          <h1 className="display-3 "> التفاصيل</h1>
          <p>
            <Link className="sub-H" to="/">
              الرئيسية
            </Link>{" "}
            /{" "}
            <Link className="sub-H" to="/subjects">
              المواد
            </Link>
          </p>
        </div>
      </div>{" "}
      {/* <!--end of header--> */}
      {isloading ? (
        <div className="row">
          <div className="col-12">
            <div className="spinner-cont">
              <div className="spinner-loader"> </div>
            </div>
          </div>
        </div>
      ) : null}
      <br />
      {errMsg ? (
        <h3 className="container alert alert-danger">{errMsg} </h3>
      ) : null}
      <div className=" cont10">
        <div className="row">
          {ordered ? (
            <h4
              className={` alert alert-success back-message ${
                ordered ? "back-message-anim" : null
              }`}>
              تم طلب الفيديو بنجاح الرجاء التواصل عن طريق االايميل او الهاتف
              الموجودة على الصفحة الرئيسية{" "}
            </h4>
          ) : null}
          {auth.user ? (
            auth.user.username !== "muaz@admin" ? (
              revLesson.length ? (
                <>
                  <button
                    type="button"
                    className="btn btn-primary order-all-btn w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    اطلب الباب
                  </button>

                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModal">
                            طلب الباب{" "}
                          </h5>
                        </div>
                        <div className="modal-body">
                          <h5>
                            بعد تأكيد طلب الباب الرجاء التواصل مع استاذ معاذ على
                            الرقم الموضح في الصفحة الرئيسية او 0912212622 لتحصل
                            على الباب المطلوب
                          </h5>
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal">
                            الغاء
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary "
                            data-bs-dismiss="modal"
                            onClick={() =>
                              multOrder(kind, booknum, 10000, auth.user._id)
                            }>
                            تأكيد الطلب
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null
            ) : null
          ) : null}

          {revLesson.length ? (
            revLesson.map((video1) => {
              return (
                <div className="col-lg-4 col-md-5 col-sm-12" key={video1._id}>
                  <div className="card">
                    <div className="card-header">
                      {video1.subject} {video1.booknum} {""} {" باب"}{" "}
                      {video1.chapter}
                    </div>
                    <div className="card-body video-body">
                      <h5 className="card-title">{video1.subName}</h5>
                      <p className="card-text">{video1.details}</p>
                      {auth.isAuthenticated ? (
                        auth.user.role === "admin" ? (
                          <div>
                            <Link
                              to={`/subjects-details?id=${video1._id}`}
                              className="btn btn-primary">
                              شاهد الفيديو
                            </Link>
                            <button
                              type="button"
                              className="btn btn-danger delete-btn"
                              data-bs-toggle="modal"
                              data-bs-target={`#l${video1._id}`}>
                              حذف
                            </button>

                            <div
                              className="modal fade"
                              id={`l${video1._id}`}
                              tabIndex="-1"
                              aria-labelledby={`l${video1._id}Label`}
                              aria-hidden="true">
                              <div className="modal-dialog">
                                <div className="modal-content">
                                  <div className="modal-header">
                                    <h5
                                      className="modal-title"
                                      id={`l${video1._id}`}>
                                      حذف الفيديو{" "}
                                    </h5>
                                  </div>
                                  <div className="modal-body">
                                    <p>
                                      هل انت متأكد برغبتك في حذف درس{" "}
                                      {video1.subName}
                                    </p>
                                  </div>
                                  <div className="modal-footer">
                                    <button
                                      type="button"
                                      className="btn btn-secondary"
                                      data-bs-dismiss="modal">
                                      الغاء
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger "
                                      data-bs-dismiss="modal"
                                      onClick={() => deletVideo(video1._id)}>
                                      حذف
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null
                      ) : // <button
                      //   className="btn btn-primary delete-btn"
                      //   onClick={() => OrderVideo(video1._id, auth.user.id)}
                      // >
                      //   اطلب الفيديو
                      // </button>
                      null}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="alert alert-success">
              لا يوجد فيديوهات انتظر قليلا
            </div>
          )}
        </div>
      </div>
      {auth.isAuthenticated ? null : (
        <div className="cont7">
          <p>
            {" "}
            <span>.ليس لديك حساب ؟ سجل لتتمكن من مشاهدة و شراء الفيديوهات</span>
            <Link className="btn btn-lg btn8" to="/login">
              {" "}
              تسجيل الدخول
            </Link>
          </p>
        </div>
      )}
      <footer>حقوق الطبع محفوظة لصالح استاذ &copy;معاذ</footer>
    </div>
  );
};

export default RevisionName;
