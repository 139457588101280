import React, { useEffect, useContext } from "react";
import { AuthContext } from "../../../context/users/authContext";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios";

const ScienceBook2 = () => {
  const { auth } = useContext(AuthContext);

  //animation package
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div data-aos="fade-right" className="row ScB2">
      <h2 className="text-center mb-4"> الرياضيات المتخصصة (2)</h2>

      {/* <!--card1--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=الاعداد المركبة&type=video/mp4">
            <img
              src="./images/subject3.png"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب الاعداد المركبة</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=الاعداد المركبة&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
      {/* <!--card2--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=التفاضل&type=video/mp4">
            <img
              src="./images/subject3.png"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب التفاضل</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=التفاضل&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
      {/* <!--card3--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=التكامل&type=video/mp4">
            <img
              src="./images/subject3.png"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب التكامل</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=التكامل&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
      {/* <!--card4--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=الدائرة&type=video/mp4">
            <img
              src="./images/subject3.png"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب الدائرة</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=الدائرة&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
      {/* <!--card5--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=الدوال&type=video/mp4">
            <img
              src="./images/subject3.png"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب الدوال الحقيقية والنهايات</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=الدوال&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
      {/* <!--card5--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=علمي&chapter=تطبيقات التفاضل&type=video/mp4">
            <img
              src="./images/subject3.png"
              className="card-img-top"
              alt="..."
            />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب تطبيقات التفاضل</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=علمي&chapter=تطبيقات التفاضل&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
    </div>
  );
};

export default ScienceBook2;
