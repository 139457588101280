import React, { useState, useContext } from "react";
import axios from "axios";
import "./blog.css";
import { AuthContext } from "../../context/users/authContext";

const AddBlog = () => {
  const { auth } = useContext(AuthContext);
  const [blogText, setBlogText] = useState("");
  const [file, setFile] = useState("");
  const [type, setType] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [arrived, setArrived] = useState(false);
  const [progress, setProgress] = useState();

  const SubmitBlog = (adminId) => {
    setIsloading(true);

    let data = new FormData();
    data.append("blogText", blogText);
    data.append("adminId", adminId);
    data.append("type", type);
    data.append("file", file);

    //post to server
    const ProgressBar = {
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    axios
      .post("/v1/blog", data, ProgressBar)
      .then((res) => {
        setIsloading(false);
        setBlogText("");
        setFile("");
        setType("");
        setArrived(true);
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };
  return (
    <div className="Blog">
      {/*modal for adding a new post */}
      <button
        type="button"
        className="blog-btn"
        data-bs-toggle="modal"
        data-bs-target="#blogModal"
      >
        اضافة اعلان
      </button>

      <div
        className="modal fade"
        id="blogModal"
        tabIndex="-1"
        aria-labelledby="blogModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="blogModal">
                اضافة اعلان جديد{" "}
              </h5>
            </div>
            <div className="modal-body">
              {/* {isloading ? (
                <div className="row">
                  <div className="col-12">
                    <div className="spinner-cont">
                      <div className="spinner-loader"></div>
                    </div>
                  </div>
                </div>
              ) : null} */}
              {progress ? (
                <div className="progress" style={{ height: 20 }}>
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${progress}%` }}
                    aria-valuenow={`${progress}`}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {progress}
                  </div>
                </div>
              ) : null}
              {arrived && (
                <h4 className="container alert alert-success">
                  {" "}
                  تمت اضافة الاعلان بنجاح
                </h4>
              )}
              <h4>قم بادخال بيانات الاعلان</h4>
              <div className="form-group">
                <label htmlFor="subject" className="label">
                  المحتوى
                </label>
                <select
                  className="form-select D-input"
                  aria-label="Default select example"
                  dir="ltr"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option defaultValue={null}>اختر</option>
                  <option value="video/mp4">فيديو</option>
                  <option value="image/*">صورة</option>
                </select>
              </div>
              <br />
              <div className="form-group">
                <input
                  type="file"
                  className="form-control D-input"
                  placeholder="اضف صورة"
                  id="file"
                  accept={type}
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
              <br />
              <div className="form-group">
                <input
                  type="text"
                  className="form-control D-input"
                  placeholder="اضف عنوان الاعلان"
                  value={blogText}
                  onChange={(e) => setBlogText(e.target.value)}
                />
              </div>
              <br />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                الغاء
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => SubmitBlog(auth.user._id)}
              >
                تأكيد
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
