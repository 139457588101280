import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/users/authContext";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import axios from "axios";

const ArtBook = () => {
  const { auth } = useContext(AuthContext);

  //animation package
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <div data-aos="fade-down" className="row ArtB">
      <h2 className="text-center mb-4">الرياضيات الاساسية</h2>
      {/* <!--card1--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=أدبي&chapter= الدوال الحقيقية والنهايات&type=video/mp4">
            <img src="./images/4-rm.png" className="card-img-top" alt="..." />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب الدوال الحقيقية والنهايات</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=أدبي&chapter= الدوال الحقيقية والنهايات&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card2--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=أدبي&chapter=التفاضل&type=video/mp4">
            <img src="./images/4-rm.png" className="card-img-top" alt="..." />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب التفاضل</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=أدبي&chapter=التفاضل&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card3--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=أدبي&chapter=التكامل&type=video/mp4">
            <img src="./images/4-rm.png" className="card-img-top" alt="..." />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب التكامل</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=أدبي&chapter=التكامل&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card4--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=أدبي&chapter=الاحصاء&type=video/mp4">
            <img src="./images/4-rm.png" className="card-img-top" alt="..." />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب الاحصاء</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=أدبي&chapter=الاحصاء&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card5--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=أدبي&chapter=الاحتمالات&type=video/mp4">
            <img src="./images/4-rm.png" className="card-img-top" alt="..." />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب الاحتمالات</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=أدبي&chapter=الاحتمالات&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}

      {/* <!--card1--> */}
      <div className="col-lg-4 col-md-6 col-sm-12 ">
        <div className="card card1">
          <Link to="/subjects-name?kind=أدبي&chapter= المصفوفات&type=video/mp4">
            <img src="./images/4-rm.png" className="card-img-top" alt="..." />
          </Link>
          <div className="card-body">
            <h5 className="card-title">
              <p> باب المصفوفات</p>
              <span className="pb-5">0 جنيه</span>
            </h5>
            <Link
              to="/subjects-name?kind=أدبي&chapter= المصفوفات&type=video/mp4"
              className="btn btn8"
            >
              التفاصيل
            </Link>{" "}
          </div>
        </div>
      </div>
      {/* <!--ends here--> */}
    </div>
  );
};

export default ArtBook;
